import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BackToTop from "../../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const LindsayBrooks = () => {
  return (
    <Layout>
      <Seo title="Doctor Lindsay Brooks, DVM" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero no-videoHero">
              <h1>
                Meet <br />
                Dr. Lindsay
                <br />
                Brooks
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Container className="acl-container mb-5 mt-5 mt-md-0">
        <div className="doctor-body">
          <Row className="border-none">
            <Col md={4} className="img-resp">
              <LazyLoadImage
                effect="blur"
                src="/assets/img/dr-lindsay-brooks.jpg"
                alt="Doctor"
                className="img-fluid mb-4"
              />
            </Col>
            <Col md={8}>
              <p>
                Dr. Brooks was born and raised in Northern California and completed
                her pre-veterinary studies at UC Santa Cruz.  She received her veterinary
                degree from St. George’s University in 2018. She then moved to Philadelphia
                for a one-year rotating internship at University of Pennsylvania. Dr.
                Brooks’ passion for orthopedics was fostered during a one-year orthopedic
                research fellowship and three-year surgical residency at University of Florida.
              </p>
              <p>
                Dr. Brooks is enthusiastic about helping animals and their people through
                improving mobility and comfort. She has particular interest in limb deformity,
                the use of 3D technology for custom surgical procedures and minimally invasive
                surgery.
              </p>
              <p>
                In her free time, Dr. Brooks enjoys exploring Colorado and all that it has
                to offer. She loves hiking with her two dogs and spending time outdoors with
                her family and friends.
              </p>
            </Col>
          </Row>
        </div>
        <div className="doctor-video">
          <Row className="border-none">
            <Col md={12}>
              <div className="embed-container">
                <iframe
                  src="https://player.vimeo.com/video/889241761"
                  frameborder="0"
                  webkitAllowFullScreen
                  mozallowfullscreen
                  allowFullScreen
                  title="doctor"
                ></iframe>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <BackToTop />
    </Layout>
  )
}

export default LindsayBrooks
